@font-face {
    font-family: 'Artex';
    src: url('./../fonts/Artex-Regular.woff2') format('woff2'),
        url('./../fonts/Artex-Regular.woff') format('woff'),
        url('./../fonts/Artex-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Artex SemiExtended';
    src: url('./../fonts/Artex-SemiExtendedRegular.woff2') format('woff2'),
        url('./../fonts/Artex-SemiExtendedRegular.woff') format('woff'),
        url('./../fonts/Artex-SemiExtendedRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Basier Square';
    src: url('./../fonts/BasierSquare-Regular.woff2') format('woff2'),
        url('./../fonts/BasierSquare-Regular.woff') format('woff'),
        url('./../fonts/BasierSquare-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Basier Square Medium';
    src: url('./../fonts/BasierSquare-Medium.woff2') format('woff2'),
        url('./../fonts/BasierSquare-Medium.woff') format('woff'),
        url('./../fonts/BasierSquare-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

html {
    font-family: 'Basier Square', 'sans-serif';
}