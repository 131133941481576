:root {
	scroll-behavior: smooth;
	/* fonts */
	--font-archivo: Archivo;
	--font-basier-square: 'Basier Square';
	--font-artex: Artex;
	--font-inter: Inter;

	/* font sizes */
	--font-size-2xs: 11px;
	--font-size-xs: 12px;
	--font-size-sm: 14px;
	--font-size-base: 15px;
	--font-size-lg: 18px;
	--font-size-xl: 21px;
	--font-size-2xl: 24px;
	--font-size-3xl: 30px;
	--font-size-4xl: 33px;
	--font-size-5xl: 45px;
	--font-size-6xl: 60px;

	/* Colors */
	--color-white: #fff;
	--color-gray-100: #f2f2f2;
	--color-gray-200: #e6e6e6;
	--color-gray-300: #0d0019;
	--color-green-100: #00e500;
	--color-green-200: #00d900;
	--color-green-300: #009f00;
	--color-indigo-100: #6f6cff;
	--color-indigo-200: #350066;
	--color-beige: #bfbfbf;

	/* border radiuses */
	--br-sm: 4px;
	--br-md: 8px;
}

.brand-animation {
	animation: brand-fadeIn 0.5s ease-out forwards;
	-webkit-animation: brand-fadeIn 0.5s ease-out forwards;
}
.fadein {
	animation: fadeIn 0.5s ease-out forwards;
	-webkit-animation: fadeIn 0.5s ease-out forwards;
}


.brand-opacity-55 {
	animation: brand-opacity-55 0.5s ease-out forwards;
	-webkit-animation: brand-opacity-55 0.5s ease-out forwards;
}
.brand-opacity-70 {
	animation: brand-opacity-70 0.5s ease-out forwards;
	-webkit-animation: brand-opacity-70 0.5s ease-out forwards;
}
.brand-opacity-85 {
	animation: brand-opacity-85 0.5s ease-out forwards;
	-webkit-animation: brand-opacity-85 0.5s ease-out forwards;
}
.brand-opacity-100 {
	animation: brand-opacity-100 0.5s ease-out forwards;
	-webkit-animation: brand-opacity-100 0.5s ease-out forwards;
}

.last-brand-animation {
	animation: brand-green-fadeIn 0.5s ease-out forwards;
	-webkit-animation: brand-green-fadeIn 0.5s ease-out forwards;
}

div {
	transition: all 0.3s ease;
}

a {
	transition: all 0.2s ease;
}

.hero-pattern path {
	/* stroke: var(--color-green-200); */
	opacity: 0;
}

.custom-invert path.a {
	fill: var(--color-gray-300);
}

.custom-invert a {
	color: var(--color-gray-300);
	border-color: var(--color-gray-300);
}

.custom-invert a.border {
	border-color: var(--color-gray-300);
}

.custom-invert a.border:hover {
	border-color: var(--color-indigo-100);
}

.custom-invert line {
	stroke: var(--color-gray-300);
}

.scrolled path.a {
	fill: transparent;
	transition: all 0.2s ease;
}
/*
.hero-pattern g.b > path.i {
  opacity: .2;
  transition: all 0.3s ease 0.5s;
}

.hero-pattern g.b > path.h {
  opacity: .4;
  transition: all 0.3s ease 1s;
}

.hero-pattern g.b > path.g {
  opacity: .6;
  transition: all 0.3s ease 1.5s;
}

.hero-pattern g.b > path.f {
  opacity: 0.8;
  transition: all 0.3s ease 2s;
}

.hero-pattern g.b > path.d {
  opacity: 1;
  transition: all 0.3s ease 2.5s;
}

.hero-pattern g.b > path.e {
  opacity: 1;
  transition: all 0.3s ease 3s;
} */

@media screen and (max-width: 768px) {
	.h-\[36px\] {
		height: 30px;
		width: 30px;
	}

	.no-height-change {
		height: auto;
		width: auto;
		font-size: 15px;
		padding: 3px 15px;
	}
}
.hero-pattern {
	width: 100%;
	z-index: 0;
	min-height: 100%;
}
@media screen and (min-width: 1400px) {
	.hero-pattern {
		min-height: 100%;
		z-index: 0;
	}
}

@media screen and (min-width: 1600px) {
	.max-w-6xl {
		max-width: 87.5rem;
	}

	.lg\:w-2\/4 {
		width: 50%;
	}

	.lg\:mt-72 {
		margin-top: 24rem;
	}
}
@media(max-width:1600px){
	.max-w-6xl {
		max-width: 76rem;
	}
}

.ff-hero {
	min-height: 100vh;
}
@media(max-width:1280px){
	#slide_wrap {padding-left: 20px;}
}
@media(max-width:991px){
	#slide_wrap {padding-left: 0px;}
}
@media(max-width:767px){
	#slide_wrap {padding-left: 30px; margin-left: -30px;}
	#slide_wrap .swiper {overflow: visible;}
	.hero-pattern {min-height: auto;}
	.menu-list ul {
		width: calc(100% - 30px);
	}
}
@media(max-width:768px){
	.hamburger {
		padding-left: 7px;
		padding-right: 7px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.footer-wrap ul:not(.contact-details) li a { font-family: 'Basier Square Medium'; }
.intern_font  { font-family: 'Inter', sans-serif }

.css-1nmdiq5-menu {
	z-index: 11 !important;
	margin: 1px 0 0 0 !important;
}
.css-1fdsijx-ValueContainer, .css-hlgwow {
	padding: 9px 20px !important;
	/* font-family: 'Basier Square medium', 'sans-serif'; */
}
.css-1hb7zxy-IndicatorsContainer, .css-1wy0on6 {
	display: none !important;
}
.css-13cymwt-control {
	/* border-color: #0D0019 !important; */
	border-color: #e6e6e6 !important;
}
.css-1dimb5e-singleValue, .css-1qrxvr1-singleValue {
	color: #0D0019 !important;
	margin: 0 !important;
}

.css-t3ipsp-control {
	border-color: rgb(111 108 255) !important;
	outline: none !important;
	box-shadow: none !important;
}

.css-tr4s17-option, .css-10wo9uf-option, .css-10wo9uf-option, .css-d7l1ni-option {
	padding: 6px 20px !important;
}
.css-1n6sfyn-MenuList {
	padding: 0 !important;
}
.css-tr4s17-option{
	background-color: #350066 !important;
}
.contact-form label, .contact-form input {
	font-size: 15px;
}
.css-1fg2n95-singleValue {
	margin: 0 !important;
}
@media(max-width:1023px){
	.css-1fdsijx-ValueContainer, .css-hlgwow {
		padding: 4px 20px !important;
	}	
	.contact-form button[type="submit"]{
		padding: 7px 10px !important;
	}
}
@media(max-width:1600px) and (min-width:1300px){
	.pattern-wrap{
		width: 150%;
		height: 125%;
	}
}

.header-main ul .menu-item {padding: 3px 15px; border: 1px solid rgb(111, 108, 255, .2); backdrop-filter: blur(3px); border-radius: 4px;}
.header-main ul li.menu-item {margin-right: 20px;}
.header-main ul .menu-item.button  {border-color: #fff;}
.custom-invert.header-main .menu-item {border-color: #E6E6E6;}
.custom-invert.header-main ul .menu-item.button { border-color: #0D0019;}
.header-main ul .menu-item.button:hover {border-color: #6f6cff !important;}
@media(min-width:1501px){
	.header-main:not(.scrolled), .contact-pop-header:not(.scrolled)  {
		padding: 80px;
	}
	.header-main.scrolled, .scrolled.contact-pop-header {
		padding: 40px 80px;
	}
}
@media(min-width:1025px){
	.pattern-wrap{
		position: absolute;
		left: 0;
		top: unset;
		bottom: 0;
		width: 100%; 
		height: 100%; 
	}
	.pattern-wrap svg {
		width: 100%;
		object-position: bottom;
		position: unset;
	}
}
@media(max-width:1920px) and (min-width:1025px) and (max-height:1050px){
	.pattern-wrap svg {
		height: 100%;
	}	
}
@media(max-width:1535px){
	.contact-pop-content .max-w-6xl {
		max-width: 81rem;
		width: 100%;
	}
	.contact-pop-content {
		min-height: calc(100vh - 226px);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		padding: 10px 40px;
	}
	.art-heading {
		padding-bottom: 60px;
	}
	.pattern-wrap {
		width: 136%;
		height: 110%;
		transform: translateX(-9%);
	}
}

@media(max-width:1200px){
	.pattern-wrap {
		width: 164%;
		height: 110%;
		transform: translateX(-6%);
	}
}

@media(max-width:992px){
	.contact-pop-content {
		padding: 0 15px;
	}
	.pattern-wrap {
		width: 250%;
		height: 110%;
		transform: translateX(-9%);
	}
}
@media(min-height:850px) and (max-height:930px) and (min-width:1440px){
	.hero-with-motion{
		min-height: 1630px;
	}
	#techFor{
		height: 230px;
	}
}
@media(min-height:800px) and (max-height:849px) and (min-width:1440px){
	.hero-with-motion{
		min-height: 1470px;
		padding-bottom: 100px;
	}
	#techFor{
		height: 160px;
	}
}
@media(max-width:767px){
	.pattern-wrap {
		width: 100%;
		height: 100%;
		transform: unset;
	}
}

