.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	background: #fff;
	padding: 2.5rem;
	/* width: 18rem !important; */
	height: 25rem !important;
	margin-bottom: 1rem;
	border: 1px solid var(--color-gray-200);
	border-radius: 0.25rem;
	font-weight: bold;
}

.swiper-button-next,
.swiper-button-prev {
	position: absolute;
	top: unset;
	bottom: 0px;
	width: 2.25rem;
	height: 2.25rem;
	line-height: 2.25rem;
	text-align: center;
	z-index: 10;
	cursor: pointer;
	background-color: var(--color-white);
	color: var(--color-gray-300);
	border: 1px solid var(--color-gray-200);
	border-radius: 0.25rem;
	display: none;
}

.swiper-button-prev:after,
.swiper-button-next:after {
	content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18.96" height="16.75" viewBox="0 0 18.96 16.75"%3E%3Cg transform="translate(0.48)"%3E%3Cpath d="M0,0,9,7.5,18,0" transform="translate(0 8.5)" fill="none" stroke="%230d0019" stroke-linejoin="round" stroke-width="1.5"/%3E%3Cline y1="15" transform="translate(9)" fill="none" stroke="%230d0019" stroke-width="1.5"/%3E%3C/g%3E%3C/svg%3E');
	display: inline-block;
	width: auto;
}

.swiper-button-prev:hover::after,
.swiper-button-next:hover::after {
	content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18.96" height="16.75" viewBox="0 0 18.96 16.75"%3E%3Cg transform="translate(0.48)"%3E%3Cpath d="M0,0,9,7.5,18,0" transform="translate(0 8.5)" fill="none" stroke="%236f6cff" stroke-linejoin="round" stroke-width="1.5"/%3E%3Cline y1="15" transform="translate(9)" fill="none" stroke="%236f6cff" stroke-width="1.5"/%3E%3C/g%3E%3C/svg%3E');
}

.swiper-button-next:after {
	margin-top: 2px;
}
.swiper-button-prev:after {
	margin-bottom: 2px;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
	left: 40px;
	right: auto;
	transform: rotate(90deg);
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
	left: 96px;
	transform: rotate(-90deg);
}
@media(min-width:1023px){
	#slide_wrap::before{
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 40px;
		height: 100%;
		background: rgb(255,255,255);
		background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		opacity: 0;
		visibility: hidden;
		z-index: 2;
	}
	#slide_wrap.mystyle::before{
		opacity: 1 ;
		visibility: visible;
	}
}
@media screen and (min-width: 768px) {
	.swiper  {
		padding-right: 40px !important;
	}
	.swiper-slide {
		width: 20rem !important;
	}
	.swiper-button-next,
	.swiper-button-prev {
		display: inline-block;
	}
}

@media screen and (min-width: 1024px) {
	.swiper-button-next,
	.swiper-rtl .swiper-button-prev {
		left: 96px;
	}
}
@media(max-width:1500px){
	.swiper-slide {
		width: 18rem !important;
	}
}
@media(max-width:767px){
	.swiper  {
		padding-right: 30px !important;
	}
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
	cursor: auto;
	pointer-events: none;
	/* opacity: 0.5; */
}

.swiper-button-next.swiper-button-disabled::after,
.swiper-button-prev.swiper-button-disabled::after {
	opacity: 0.35;
}

