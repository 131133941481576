@keyframes fadeIn {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0.2;
	}
	80% {
		opacity: 0.4;
		stroke: var(--color-indigo-100);
	}
	100% {
		opacity: 0.4;
		stroke: var(--color-indigo-100);
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0.2;
	}
	80% {
		opacity: 0.4;
		stroke: var(--color-indigo-100);
	}
	100% {
		opacity: 0.4;
		stroke: var(--color-indigo-100);
	}
}
@-webkit-keyframes brand-fadeIn {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0.2;
	}
	80% {
		opacity: 0.4;
		stroke: var(--color-indigo-100);
	}
	100% {
		opacity: 0.4;
		stroke: var(--color-indigo-100);
	}
}


@keyframes brand-fadeIn {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0.2;
	}
	80% {
		opacity: 0.4;
		stroke: var(--color-indigo-100);
	}
	100% {
		opacity: 0.4;
		stroke: var(--color-indigo-100);
	}
}


@keyframes brand-opacity-55 {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0.2;
	}
	80% {
		opacity: 0.55;
		stroke: var(--color-indigo-100);
	}
	100% {
		opacity: 0.55;
		stroke: var(--color-indigo-100);
	}
}
@-webkit-keyframes brand-opacity-55 {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0.2;
	}
	80% {
		opacity: 0.55;
		stroke: var(--color-indigo-100);
	}
	100% {
		opacity: 0.55;
		stroke: var(--color-indigo-100);
	}
}

@keyframes brand-opacity-70 {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0.2;
	}
	80% {
		opacity: 0.7;
		stroke: var(--color-indigo-100);
	}
	100% {
		opacity: 0.7;
		stroke: var(--color-indigo-100);
	}
}
@-webkit-keyframes brand-opacity-70 {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0.2;
	}
	80% {
		opacity: 0.7;
		stroke: var(--color-indigo-100);
	}
	100% {
		opacity: 0.7;
		stroke: var(--color-indigo-100);
	}
}

@keyframes brand-opacity-100 {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0.2;
	}
	80% {
		opacity: 1;
		stroke: var(--color-indigo-100);
	}
	100% {
		opacity: 1;
		/* stroke: var(--color-indigo-100); */
		stroke: #6F6CFF;
		
	}
}
@-webkit-keyframes brand-opacity-100 {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0.2;
	}
	80% {
		opacity: 1;
		stroke: var(--color-indigo-100);
	}
	100% {
		opacity: 1;
		/* stroke: var(--color-indigo-100); */
		stroke: #6F6CFF;
		
	}
}

@keyframes brand-opacity-85 {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0.2;
	}
	80% {
		opacity: 0.85;
		stroke: var(--color-indigo-100);
	}
	100% {
		opacity: 0.85;
		stroke: var(--color-indigo-100);
	}
}
@-webkit-keyframes brand-opacity-85 {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0.2;
	}
	80% {
		opacity: 0.85;
		stroke: var(--color-indigo-100);
	}
	100% {
		opacity: 0.85;
		stroke: var(--color-indigo-100);
	}
}

@keyframes brand-green-fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
		stroke: var(--color-green-200);
	}
}

@-webkit-keyframes brand-green-fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
		stroke: var(--color-green-200);
	}
}



.animation-delay-0 {
	animation-delay: 0ms;
	-webkit-animation-delay: 0ms;
}
.animation-delay-50 {
	animation-delay: 50ms;
	-webkit-animation-delay: 50ms;
}
.animation-delay-100 {
	animation-delay: 100ms;
	-webkit-animation-delay: 100ms;
}
.animation-delay-150 {
	animation-delay: 150ms;
	-webkit-animation-delay: 150ms;
}
.animation-delay-200 {
	animation-delay: 200ms;
	-webkit-animation-delay: 200ms;
}
.animation-delay-250 {
	animation-delay: 250ms;
	-webkit-animation-delay: 250ms;
}
.animation-delay-300 {
	animation-delay: 300ms;
	-webkit-animation-delay: 300ms;
}
.animation-delay-350 {
	animation-delay: 350ms;
	-webkit-animation-delay: 350ms;
}
.animation-delay-400 {
	animation-delay: 400ms;
	-webkit-animation-delay: 400ms;
}
.animation-delay-450 {
	animation-delay: 450ms;
	-webkit-animation-delay: 450ms;
}
.animation-delay-500 {
	animation-delay: 500ms;
	-webkit-animation-delay: 500ms;
}
.animation-delay-550 {
	animation-delay: 550ms;
	-webkit-animation-delay: 550ms;
}
.animation-delay-600 {
	animation-delay: 600ms;
	-webkit-animation-delay: 600ms;
}
.animation-delay-650 {
	animation-delay: 650ms;
	-webkit-animation-delay: 650ms;
}
.animation-delay-700 {
	animation-delay: 700ms;
	-webkit-animation-delay: 700ms;
}
.animation-delay-750 {
	animation-delay: 750ms;
	-webkit-animation-delay: 750ms;
}
.animation-delay-800 {
	animation-delay: 800ms;
	-webkit-animation-delay: 800ms;
}
.animation-delay-850 {
	animation-delay: 850ms;
	-webkit-animation-delay: 850ms;
}
.animation-delay-900 {
	animation-delay: 900ms;
	-webkit-animation-delay: 900ms;
}
.animation-delay-950 {
	animation-delay: 950ms;
	-webkit-animation-delay: 950ms;
}
.animation-delay-1000 {
	animation-delay: 1000ms;
	-webkit-animation-delay: 1000ms;
}
.animation-delay-1050 {
	animation-delay: 1050ms;
	-webkit-animation-delay: 1050ms;
}
.animation-delay-1100 {
	animation-delay: 1100ms;
	-webkit-animation-delay: 1100ms;
}
.animation-delay-1150 {
	animation-delay: 1150ms;
	-webkit-animation-delay: 1150ms;
}
.animation-delay-1200 {
	animation-delay: 1200ms;
	-webkit-animation-delay: 1200ms;
}
.animation-delay-1250 {
	animation-delay: 1250ms;
	-webkit-animation-delay: 1250ms;
}
.animation-delay-1300 {
	animation-delay: 1300ms;
	-webkit-animation-delay: 1300ms;
}
.animation-delay-1350 {
	animation-delay: 1350ms;
	-webkit-animation-delay: 1350ms;
}
.animation-delay-1400 {
	animation-delay: 1400ms;
	-webkit-animation-delay: 1400ms;
}
.animation-delay-1450 {
	animation-delay: 1450ms;
	-webkit-animation-delay: 1450ms;
}
.animation-delay-1500 {
	animation-delay: 1500ms;
	-webkit-animation-delay: 1500ms;
}
.animation-delay-1550 {
	animation-delay: 1550ms;
	-webkit-animation-delay: 1550ms;
}
.animation-delay-1600 {
	animation-delay: 1600ms;
	-webkit-animation-delay: 1600ms;
}
.animation-delay-1650 {
	animation-delay: 1650ms;
	-webkit-animation-delay: 1650ms;
}
.animation-delay-1700 {
	animation-delay: 1700ms;
	-webkit-animation-delay: 1700ms;
}
.animation-delay-1750 {
	animation-delay: 1750ms;
	-webkit-animation-delay: 1750ms;
}
.animation-delay-1800 {
	animation-delay: 1800ms;
	-webkit-animation-delay: 1800ms;
}
.animation-delay-1850 {
	animation-delay: 1850ms;
	-webkit-animation-delay: 1850ms;
}
.animation-delay-1900 {
	animation-delay: 1900ms;
	-webkit-animation-delay: 1900ms;
}
.animation-delay-1950 {
	animation-delay: 1950ms;
	-webkit-animation-delay: 1950ms;
}
.animation-delay-2000 {
	animation-delay: 2000ms;
	-webkit-animation-delay: 2000ms;
}
.animation-delay-2050 {
	animation-delay: 2050ms;
	-webkit-animation-delay: 2050ms;
}
.animation-delay-2100 {
	animation-delay: 2100ms;
	-webkit-animation-delay: 2100ms;
}
.animation-delay-2150 {
	animation-delay: 2150ms;
	-webkit-animation-delay: 2150ms;
}
.animation-delay-2200 {
	animation-delay: 2200ms;
	-webkit-animation-delay: 2200ms;
}
.animation-delay-2250 {
	animation-delay: 2250ms;
	-webkit-animation-delay: 2250ms;
}
.animation-delay-2300 {
	animation-delay: 2300ms;
	-webkit-animation-delay: 2300ms;
}
.animation-delay-2350 {
	animation-delay: 2350ms;
	-webkit-animation-delay: 2350ms;
}
.animation-delay-2400 {
	animation-delay: 2400ms;
	-webkit-animation-delay: 2400ms;
}
.animation-delay-2450 {
	animation-delay: 2450ms;
	-webkit-animation-delay: 2450ms;
}
.animation-delay-2500 {
	animation-delay: 2550ms;
	-webkit-animation-delay: 2550ms;
}
.animation-delay-2600 {
	animation-delay: 2600ms;
	-webkit-animation-delay: 2600ms;
}
.animation-delay-2700 {
	animation-delay: 2700ms;
	-webkit-animation-delay: 2700ms;
}
.animation-delay-2800 {
	animation-delay: 2800ms;
	-webkit-animation-delay: 2800ms;
}
.animation-delay-2900 {
	animation-delay: 2900ms;
	-webkit-animation-delay: 2900ms;
}
.animation-delay-3000 {
	animation-delay: 3000ms;
	-webkit-animation-delay: 3000ms;
}
.animation-delay-3100 {
	animation-delay: 3100ms;
	-webkit-animation-delay: 3100ms;
}
.animation-delay-3200 {
	animation-delay: 3200ms;
	-webkit-animation-delay: 3200ms;
}
.animation-delay-3300 {
	animation-delay: 3300ms;
	-webkit-animation-delay: 3300ms;
}
.animation-delay-3400 {
	animation-delay: 3400ms;
	-webkit-animation-delay: 3400ms;
}
.animation-delay-3500 {
	animation-delay: 3500ms;
	-webkit-animation-delay: 3500ms;
}
.animation-delay-3600 {
	animation-delay: 3600ms;
	-webkit-animation-delay: 3600ms;
}
.animation-delay-3700 {
	animation-delay: 3700ms;
	-webkit-animation-delay: 3700ms;
}
.animation-delay-3800 {
	animation-delay: 3800ms;
	-webkit-animation-delay: 3800ms;
}
.animation-delay-3900 {
	animation-delay: 3900ms;
	-webkit-animation-delay: 3900ms;
}
.animation-delay-4000 {
	animation-delay: 4000ms;
	-webkit-animation-delay: 4000ms;
}
.animation-delay-4100 {
	animation-delay: 4100ms;
	-webkit-animation-delay: 4100ms;
}
.animation-delay-4200 {
	animation-delay: 4200ms;
	-webkit-animation-delay: 4200ms;
}

@media(max-width:767px){
	.animation-delay-50 {
		animation-delay: 40ms;
		-webkit-animation-delay: 40ms;
	}
	.animation-delay-100 {
		animation-delay: 80ms;
		-webkit-animation-delay: 80ms;
	}
	.animation-delay-150 {
		animation-delay: 120ms;
		-webkit-animation-delay: 120ms;
	}
	.animation-delay-200 {
		animation-delay: 160ms;
		-webkit-animation-delay: 160ms;
	}
	.animation-delay-250 {
		animation-delay: 200ms;
		-webkit-animation-delay: 200ms;
	}
	.animation-delay-300 {
		animation-delay: 240ms;
		-webkit-animation-delay: 240ms;
	}
	.animation-delay-350 {
		animation-delay: 280ms;
		-webkit-animation-delay: 280ms;
	}
	.animation-delay-400 {
		animation-delay: 320ms;
		-webkit-animation-delay: 320ms;
	}
	.animation-delay-450 {
		animation-delay: 360ms;
		-webkit-animation-delay: 360ms;
	}
	.animation-delay-500 {
		animation-delay: 400ms;
		-webkit-animation-delay: 400ms;
	}
	.animation-delay-550 {
		animation-delay: 440ms;
		-webkit-animation-delay: 440ms;
	}
	.animation-delay-600 {
		animation-delay: 480ms;
		-webkit-animation-delay: 480ms;
	}
	.animation-delay-650 {
		animation-delay: 520ms;
		-webkit-animation-delay: 520ms;
	}
	.animation-delay-700 {
		animation-delay: 560ms;
		-webkit-animation-delay: 560ms;
	}
	.animation-delay-750 {
		animation-delay: 600ms;
		-webkit-animation-delay: 600ms;
	}
	.animation-delay-800 {
		animation-delay: 640ms;
		-webkit-animation-delay: 640ms;
	}
	.animation-delay-850 {
		animation-delay: 680ms;
		-webkit-animation-delay: 680ms;
	}
	.animation-delay-900 {
		animation-delay: 720ms;
		-webkit-animation-delay: 720ms;
	}
	.animation-delay-950 {
		animation-delay: 760ms;
		-webkit-animation-delay: 760ms;
	}
	.animation-delay-1000 {
		animation-delay: 800ms;
		-webkit-animation-delay: 800ms;
	}
	.animation-delay-1050 {
		animation-delay: 840ms;
		-webkit-animation-delay: 840ms;
	}
	.animation-delay-1100 {
		animation-delay: 880ms;
		-webkit-animation-delay: 880ms;
	}
	.animation-delay-1150 {
		animation-delay: 920ms;
		-webkit-animation-delay: 920ms;
	}
	.animation-delay-1200 {
		animation-delay: 960ms;
		-webkit-animation-delay: 960ms;
	}
	.animation-delay-1250 {
		animation-delay: 1000ms;
		-webkit-animation-delay: 1000ms;
	}
	.animation-delay-1300 {
		animation-delay: 1040ms;
		-webkit-animation-delay: 1040ms;
	}
	.animation-delay-1350 {
		animation-delay: 1080ms;
		-webkit-animation-delay: 1080ms;
	}
	.animation-delay-1400 {
		animation-delay: 1120ms;
		-webkit-animation-delay: 1120ms;
	}
	.animation-delay-1450 {
		animation-delay: 1160ms;
		-webkit-animation-delay: 1160ms;
	}
	.animation-delay-1500 {
		animation-delay: 1200ms;
		-webkit-animation-delay: 1200ms;
	}
	.animation-delay-1550 {
		animation-delay: 1240ms;
		-webkit-animation-delay: 1240ms;
	}
	.animation-delay-1600 {
		animation-delay: 1280ms;
		-webkit-animation-delay: 1280ms;
	}
	.animation-delay-1650 {
		animation-delay: 1320ms;
		-webkit-animation-delay: 1320ms;
	}
	.animation-delay-1700 {
		animation-delay: 1360ms;
		-webkit-animation-delay: 1360ms;
	}
	.animation-delay-1750 {
		animation-delay: 1400ms;
		-webkit-animation-delay: 1400ms;
	}
	.animation-delay-1800 {
		animation-delay: 1440ms;
		-webkit-animation-delay: 1440ms;
	}
	.animation-delay-1850 {
		animation-delay: 1480ms;
		-webkit-animation-delay: 1480ms;
	}
	.animation-delay-1900 {
		animation-delay: 1520ms;
		-webkit-animation-delay: 1520ms;
	}
	.animation-delay-1950 {
		animation-delay: 1560ms;
		-webkit-animation-delay: 1560ms;
	}
	.animation-delay-2000 {
		animation-delay: 1600ms;
		-webkit-animation-delay: 1600ms;
	}
	.animation-delay-2050 {
		animation-delay: 1640ms;
		-webkit-animation-delay: 1640ms;
	}
	.animation-delay-2100 {
		animation-delay: 1680ms;
		-webkit-animation-delay: 1680ms;
	}
	.animation-delay-2150 {
		animation-delay: 1720ms;
		-webkit-animation-delay: 1720ms;
	}
	.animation-delay-2200 {
		animation-delay: 1760ms;
		-webkit-animation-delay: 1760ms;
	}
	.animation-delay-2250 {
		animation-delay: 1800ms;
		-webkit-animation-delay: 1800ms;
	}
	.animation-delay-2300 {
		animation-delay: 1840ms;
		-webkit-animation-delay: 1840ms;
	}
	.animation-delay-2350 {
		animation-delay: 1880ms;
		-webkit-animation-delay: 1880ms;
	}
	.animation-delay-2400 {
		animation-delay: 1920ms;
		-webkit-animation-delay: 1920ms;
	}
	.animation-delay-2450 {
		animation-delay: 1960ms;
		-webkit-animation-delay: 1960ms;
	}
	.animation-delay-2500 {
		animation-delay: 2000ms;
		-webkit-animation-delay: 2000ms;
	}
	.animation-delay-2600 {
		animation-delay: 2040ms;
		-webkit-animation-delay: 2040ms;
	}
	.animation-delay-2700 {
		animation-delay: 2080ms;
		-webkit-animation-delay: 2080ms;
	}
	.animation-delay-2800 {
		animation-delay: 2120ms;
		-webkit-animation-delay: 2120ms;
	}
	.animation-delay-2900 {
		animation-delay: 2160ms;
		-webkit-animation-delay: 2160ms;
	}
	.animation-delay-3000 {
		animation-delay: 2200ms;
		-webkit-animation-delay: 2200ms;
	}
	.animation-delay-3100 {
		animation-delay: 2240ms;
		-webkit-animation-delay: 2240ms;
	}
	.animation-delay-3200 {
		animation-delay: 2280ms;
		-webkit-animation-delay: 2280ms;
	}
	.animation-delay-3300 {
		animation-delay: 2320ms;
		-webkit-animation-delay: 2320ms;
	}
	.animation-delay-3400 {
		animation-delay: 2360ms;
		-webkit-animation-delay: 2360ms;
	}
	.animation-delay-3500 {
		animation-delay: 2400ms;
		-webkit-animation-delay: 2400ms;
	}
	.animation-delay-3600 {
		animation-delay: 2440ms;
		-webkit-animation-delay: 2440ms;
	}
	.animation-delay-3700 {
		animation-delay: 2480ms;
		-webkit-animation-delay: 2480ms;
	}
	.animation-delay-3800 {
		animation-delay: 2520ms;
		-webkit-animation-delay: 2520ms;
	}
	.animation-delay-3900 {
		animation-delay: 2560ms;
		-webkit-animation-delay: 2560ms;
	}
	.animation-delay-4000 {
		animation-delay: 2600ms;
		-webkit-animation-delay: 2600ms;
	}
	.animation-delay-4100 {
		animation-delay: 2640ms;
		-webkit-animation-delay: 2640ms;
	}
	.animation-delay-4200 {
		animation-delay: 2680ms;
		-webkit-animation-delay: 2680ms;
	}
}

/****for IOS Mobile *****/
@supports (-webkit-touch-callout: none) {
	svg .first-line{
		animation-delay: 2100ms;
		-webkit-animation-delay: 2100ms;
	}
  }